<template>
    <section class="proveedores-ver-materiales container-fluid overflow-auto custom-scroll mt-2">
        <titulo-divisor titulo="Materiales cotizados">
            <div class="row">
                <div class="col auto d-none">
                    <el-input v-model="buscarListado" placeholder="Buscar proveedores" size="small" />
                </div>
                <div v-if="cantFiltros == 0" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="toggleFilterModal">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="cantFiltros > 0" class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="toggleFilterModal">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12">{{ cantFiltros }}</p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="limpiarFiltro" />
                        </div>
                    </div>
                </div>
            </div>
        </titulo-divisor>
        <div class="d-flex justify-content-lg-end">
            <search class="w-25" :data-to-search="listadoMateriales.data" :auto-search="false" placeholder="Buscar material" @filtered="refreshMaterialList" />
        </div>
        <tabla-general :data="listadoMateriales.data" :usar-paginacion="false" :mostrar-buscador="false">
            <el-table-column label="Material" prop="material">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos cr-pointer f-600">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cotización" prop="referencia_cotizacion" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.referencia_cotizacion }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor cotizado" prop="valor_cotizado" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor_cotizado) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha cotización" prop="fecha_cotizacion" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.fecha_cotizacion }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Veces cotizado" prop="veces_cotizado" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.veces_cotizado }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor de compra" prop="valor_compra" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ formatoMoneda(scope.row.valor_compra) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Fecha compra" prop="fecha_compra" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.fecha_compra }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12">
                    <p class="input-label-top">Rango fecha cotizacion</p>
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Desde</p>
                    <el-date-picker 
                    v-model="filter.fecha_cotizacion_inicio" 
                    :picker-options="fecha_cotizacion_inicio_options" 
                    type="date" 
                    placeholder="Seleccionar un día" 
                    size="small" 
                    class="w-100"
                    />
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Hasta</p>
                    <el-date-picker 
                    v-model="filter.fecha_cotizacion_fin" 
                    :picker-options="fecha_cotizacion_fin_options" 
                    type="date" 
                    placeholder="Seleccionar un día" 
                    size="small" 
                    class="w-100"
                    />
                </div>
                <div class="col-12">
                    <p class="input-label-top">Rango fecha compra</p>
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Desde</p>
                    <el-date-picker 
                    v-model="filter.fecha_compra_inicio"
                    :picker-options="fecha_compra_inicio_options" 
                    type="date" 
                    placeholder="Seleccionar un día" 
                    size="small" 
                    class="w-100"
                    />
                </div>
                <div class="col-6 mb-3">
                    <p class="input-label-top">Hasta</p>
                    <el-date-picker 
                    v-model="filter.fecha_compra_fin"
                    :picker-options="fecha_compra_fin_options" 
                    type="date" 
                    placeholder="Seleccionar un día" 
                    size="small" 
                    class="w-100"
                    />
                </div>
                <hr />
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de valor cotizado</p>
                    <el-slider v-model="range_value_cotization" range :min="range_cotizado[0]" :max="range_cotizado[1]" :format-tooltip="formatTooltipRange" :disabled="disableSliderCotizacion" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de valor de compra</p>
                    <el-slider v-model="range_value_purchase" range :min="range_compra[0]" :max="range_compra[1]" :format-tooltip="formatTooltipRange" :disabled="disableSliderCompra" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="handleFilter"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { ProviderMaterialFilterFactory } from "~/domain/filters/proveedores/materiales/factories/ProviderMaterialFilterFactory"
import { getRange } from '~/services/global'
import moment from 'moment'
export default {
    data(){
        return{
            buscarListado:'',
            valueSelect: '',
            range_cotizado: [0,0],
            range_compra: [0,0]
        }
    },
    computed:{
        ...mapGetters({
            listadoMateriales: 'proveedores/ver/ver/provider_materials',
        }),
        id_proveedor(){
            return this.$route.params?.id_proveedor
        },
        filter:{
            get(){
                return this.$store.getters['proveedores/ver/materiales/filter']
            },
            set(val){
                this.$store.commit('proveedores/ver/materiales/set_filter', val)
            }
        },
        range_value_cotization:{
            get(){
                return [
                    this.filter.valor_cotizado_inicio, 
                    this.filter.valor_cotizado_fin
                ]
            },
            set(val){
                this.filter.valor_cotizado_inicio = val[0]
                this.filter.valor_cotizado_fin = val[1]
            }
        },
        range_value_purchase:{
            get(){
                return [
                    this.filter.valor_compra_inicio, 
                    this.filter.valor_compra_fin
                ]
            },
            set(val){
                this.filter.valor_compra_inicio = val[0]
                this.filter.valor_compra_fin = val[1]
            }
        },
        cantFiltros(){
            return this.filter.count()
        },
        disableSliderCompra(){
            return this.range_compra.every(el => el === 0)
        },
        disableSliderCotizacion(){
            return this.range_cotizado.every(el => el === 0)
        },
        fecha_cotizacion_inicio_options(){
            return {
                disabledDate:(time) => {
                    const fecha_fin = this.filter.fecha_cotizacion_fin
                    const date = moment(time).format('YYYY-MM-DD')
                    return fecha_fin && date > moment(fecha_fin).format('YYYY-MM-DD')
                }
            }
        },
        fecha_cotizacion_fin_options(){
            return {
                disabledDate:(time) => {
                    const fecha_inicio = this.filter.fecha_cotizacion_inicio
                    const date = moment(time).format('YYYY-MM-DD')
                    return fecha_inicio && date < moment(fecha_inicio).format('YYYY-MM-DD')
                }
            }
        },
        fecha_compra_inicio_options(){
            return {
                disabledDate:(time) => {
                    const fecha_fin = this.filter.fecha_compra_fin
                    const date = moment(time).format('YYYY-MM-DD')
                    return fecha_fin && date > moment(fecha_fin).format('YYYY-MM-DD')
                }
            }
        },
        fecha_compra_fin_options(){
            return {
                disabledDate:(time) => {
                    const fecha_inicio = this.filter.fecha_compra_inicio
                    const date = moment(time).format('YYYY-MM-DD')
                    return fecha_inicio && date < moment(fecha_inicio).format('YYYY-MM-DD')
                }
            }
        }

    },
    async mounted(){
        const {
            fecha_compra_inicio,
            fecha_compra_fin,
            fecha_cotizacion_inicio,
            fecha_cotizacion_fin,
            valor_cotizado_inicio,
            valor_cotizado_fin,
            valor_compra_inicio,
            valor_compra_fin,
        } = this.$route.query

        const filter = ProviderMaterialFilterFactory.instantiateEmpty()

        this.filter = ProviderMaterialFilterFactory.instantiateEmpty({
            fecha_cotizacion_inicio: fecha_cotizacion_inicio ?? filter.fecha_cotizacion_inicio,
            fecha_cotizacion_fin: fecha_cotizacion_fin ?? filter.fecha_cotizacion_fin,
            fecha_compra_inicio: fecha_compra_inicio ?? filter.fecha_compra_inicio,
            fecha_compra_fin: fecha_compra_fin ?? filter.fecha_compra_fin,
            valor_cotizado_inicio: valor_cotizado_inicio ?? filter.valor_cotizado_inicio,
            valor_cotizado_fin: valor_cotizado_fin ?? filter.valor_cotizado_fin,
            valor_compra_inicio: valor_compra_inicio ?? filter.valor_compra_inicio,
            valor_compra_fin: valor_compra_fin ?? filter.valor_compra_fin,
        })
    
        this.$router.push({query: this.filter.clean()})

        await this.obtenerListadoMateriales({
            id_proveedor: this.id_proveedor,
            payload: this.filter.clean(),
        })

        this.range_cotizado = getRange(this.listadoMateriales, 'valor_cotizado')
        this.range_compra = getRange(this.listadoMateriales, 'valor_compra')
    },
    methods:{
        ...mapActions({
            obtenerListadoMateriales: 'proveedores/ver/ver/Action_get_provider_materials',
            regenerarListadoMateriales: 'proveedores/ver/ver/Action_regenerate_provider_materials',
            clearFilter: 'proveedores/ver/materiales/clear_filter',
        }),

        ...mapMutations({
            setListadoMateriales: 'proveedores/ver/ver/set_provider_materials'
        }),
        toggleFilterModal(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async filtrarMateriales(){
            this.$router.push({query: this.filter.clean()})
            await this.obtenerListadoMateriales({id_proveedor: this.id_proveedor, payload: this.filter.clean()})
        },
        async handleFilter(){
            await this.filtrarMateriales()
            this.toggleFilterModal()
        },
        limpiarFiltro(){
            this.clearFilter()
            this.filtrarMateriales()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async refreshMaterialList(data){
            if(data.length)
                return this.setListadoMateriales(data)
            this.regenerarListadoMateriales()
        }
    }

}
</script>

<style lang="scss" scoped>
.proveedores-ver-materiales{
    height: calc(100vh - 445px);
}
</style>